<template>
  <v-menu transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        :value="formattedData"
        readonly
        filled
        background-color="transparent"
        hide-details
        v-bind="attrs"
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-date-picker
      :value="formattedData"
      @change="change"
      no-title
      scrollable
      width="240"
      prepend-icon="mdi-calendar"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "options"],
  data() {
    return { menu: false };
  },
  computed: {
    formattedData() {
      return (this.value || "").slice(0, 10);
    },
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
